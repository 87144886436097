@import '../../styles';

.cards {
  padding: 40px;
  width: 1020px;
  @include fade-in;

  @media (max-width: 1000px) {
    width: calc(100% - 20px);
    padding: 20px 10px;
  }
  &__wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-auto-rows: minmax(175px, 1fr);
    gap: 10px;
  }
  &__header {
    display: flex;
    color: $superdark-grey;
    justify-content: space-between;
    align-items: baseline;
    border-bottom: 1px solid $light-grey;
    margin-bottom: 20px;
  }
}