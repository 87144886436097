@import '../../../styles';

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
  border-radius: 5px;
  width: 100%;
  color: $superlight-grey;
  background-color: $ultradark-grey;
  @include box-shadow(0.3);
  @include transition(0.25s);
  @include fade-in;

  &__image {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.25s ease;
  }

  .heading-wrapper {
    padding: 0px 20px;
    z-index: 100;
  }

  .tour-btn-wrapper {
    display: flex;
    justify-content: flex-start;
    transition: all 0.25s ease;
    margin-top: 20px;
    padding: 0px;
    width: 100%;
    height: 0;
    opacity: 0;
    z-index: 999;

    &:before {
      content: '';
      position: absolute;
      width: 360px;
      height: 1px;
      border-top: 1px solid white;
      bottom: 0;
      z-index: 999;
      opacity: 0;
      transition: all 0.25s ease;
    }

    button {
      &:hover {
        box-shadow: none;
      }
    }
  }

  &:hover,
  &.focused {
    .card__image {
      transform: scale(1.05);
    }

    p {
      margin-bottom: 0;
    }

    .tour-btn-wrapper {
      opacity: 1;
      height: 30px;
      margin: 10px 0px;
      padding-top: 10px;
      @include text-shadow(0.9);

      &:before {
        bottom: 38px;
        opacity: 0.1;
      }
    }
  }
  button {
    position: relative;
    z-index: 1;
    border: none;
    padding: 0;
    color: $medium-grey;
    padding: 0px 10px;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      color: $light-grey;
    }

    &:active {
      color: white;
    }
  }
  
  &:after {
    content: '';
    pointer-events: none;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: -moz-linear-gradient(
      top,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.65) 100%
    );
    background: -webkit-linear-gradient(
      top,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.65) 100%
    );
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.65) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(
        startColorstr='#00000000',
        endColorstr='#a6000000',
        GradientType=0
      );
    z-index: 0;
  }
}