@import '../../../styles';

.dropdown-list {
  padding: unset;
  position: absolute;
  top: 35px;
  right: 0px;
  border-radius: 0 0 0 3px;
  z-index: 1;
  color: $superdark-grey;
  width: 150px;
  @include box-shadow(0.25);

  .dropdown-option {
    display: flex;
    align-items: center;
    list-style: none;
    height: auto;
    padding: 10px 30px;
    font-size: 14px;
    background-color: $superlight-grey;
    border-bottom: 1px solid $superlight-grey;
    font-family: 'Open Sans', sans-serif;
    transition: all 0.25s ease;
    .logout {
      border-style: none;
      color: $superdark-grey;
      cursor: pointer;
      width: 100%;
      padding: 10px 0px;
      text-align: left;
      font-weight: 500;
      text-transform: capitalize;
      font-size: 14px;
      font-family: 'Open Sans', sans-serif;
      box-shadow: none;
    }

    &.active,
    &:hover {
      color: $ultradark-grey;
      background: darken($light-grey, 3%);
      box-shadow: none;
      cursor: pointer;
    }

  }
}
