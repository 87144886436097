@import '../../styles';

.avatar {
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px 0 0;
  cursor: pointer;
  &.no-image {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $dark-blue;
    color: white;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: $rem-font-size-small-3;
  }
}
