@import '../../styles';

.header {
  background: $superlight-grey;
  display: flex;
  align-items: center;
  height: 48px;
  color: $superlight-grey;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  @include box-shadow(0.2);

  &__nav-btns {
    display: flex;
    align-items: center;
  }

  &.hidden {
    display: none;
  }

  .c3d-btn {
    width: 48px;
    height: 48px;
    padding: 10px 0;
    text-align: center;
    transition: all 0.25s ease;
    box-sizing: border-box;

    &:hover {
      background: darken($superlight-grey, 2%);
    }

    &:active {
      background: $light-grey;
    }
  }

  .app-portal-btn {
    width: 30px;
  }

  &__home-btn {
    color: $superdark-grey;
    font-size: $rem-font-size-medium-3;
    text-transform: none;
    border: none;
    border-radius: 0;
    height: 100%;
    padding: 0 20px;
    border-left: 1px solid $light-grey;
    border-right: 1px solid $light-grey;
    transition: all 0.25s ease;
    line-height: 50px;
  
    @include mobile {
      font-size: $rem-font-size-small-3;
    }

    i {
      color: $superdark-grey;
      vertical-align: -2px;
      transition: all 0.25s ease;
    }

    &:hover {
      box-shadow: none !important;
      color: $ultradark-grey !important;

      i {
        color: $ultradark-grey;
      }
    }

    &:active {
      color: black;
      background: $light-grey;

      i {
        color: $ultradark-grey;
      }
    }
  }

  .profile-wrapper {
    display: flex;
    margin-left: auto;
    align-content: center;

    .user-profile {
      display: flex;
      align-items: center;
      border-style: none;
      color: $superdark-grey;
      cursor: pointer;
      padding: 0 15px;
      outline: none;
      border-radius: 0;
      border-left: 1px solid #e8e8e8;
      height: 48px;

      img {
        transition: all 0.25s ease;
      }

      &:focus {
        outline: 2px solid $light-blue;
      }

      &:hover {
        color: $ultradark-grey;
        box-shadow: none;

        img {
          @include box-shadow(0.15);
        }

        &:after {
          border-top: 5px solid #e8e8e8;
        }
      }

      &:active {
        color: black;
      }

      i {
        font-size: $rem-font-size-large-1;
        vertical-align: -6px;
        margin-left: 3px;
      }
    }
  }

  &__link-btn {
    margin-left: 10px;
    padding: 5px 10px;
    cursor: pointer;
    color: $blue;
    border: none;
    font-size: $rem-font-size-medium-1;

    &:hover {
      color: $light-blue !important;
      box-shadow: none !important;
    }

    &:active {
      color: darken($blue, 10%);
    }
  }

  .header-btn {
    width: 48px;
    height: 48px;
    border-left: 1px solid $light-grey;
    border-radius: 0;
    color: $dark-grey;
    text-decoration: none;
    outline: none;

    &:hover {
      color: $superdark-grey;
    }

    &:active {
      color: $ultradark-grey;
      background: $light-grey;
    }

    a {
      &:visited {
        color: $superlight-grey;
      }

      &:hover {
        color: $ultradark-grey;
      }
    }

    i {
      font-size: $rem-font-size-large-2;
    }

    &.publish {
      position: relative;

      .circle {
        background: $error;
        color: white;
        border-radius: 50%;
        height: 14px;
        width: 14px;
        display: block;
        position: absolute;
        top: 11px;
        right: 4px;
        font-size: $rem-font-size-tiny-3;
        line-height: 12px;
        pointer-events: none;
        padding-top: 2px;

        &.small {
          font-size: $rem-font-size-tiny-2;
          line-height: 14px;
        }
      }
    }
  }

  .avatar {
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px 0 0;
    cursor: pointer;
  }

  .dropdown {
    position: relative;
    padding: 0;
    color: $superdark-grey;
    background: $superlight-grey;
    border-style: none;
    border-radius: 0 0 0 3px;
    z-index: 3;
  }
  &.disabled {
    pointer-events: none;
  }
}
