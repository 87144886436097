// 4.8px
$rem-font-size-tiny-1: 0.3rem;
// 9.6px
$rem-font-size-tiny-2: 0.6rem;
// 11.2px
$rem-font-size-tiny-3: 0.7rem;
// 12px
$rem-font-size-small-1: 0.75rem;
// 12.8px
$rem-font-size-small-2: 0.8rem;
// 13.6px
$rem-font-size-small-3: 0.85rem;
// 14.4px
$rem-font-size-small-4: 0.9rem;
// 15.2px
$rem-font-size-small-5: 0.95rem;
// ** BASE FONT SIZE ** 16 px
$rem-font-size-medium-1: 1rem;
// 17.6px
$rem-font-size-medium-2: 1.1rem;
// 19.2px
$rem-font-size-medium-3: 1.2rem;
// 20px
$rem-font-size-medium-4: 1.25rem;
// 20.8px
$rem-font-size-medium-5: 1.3rem;
// 21.6px
$rem-font-size-medium-6: 1.35rem;
// 22.4px
$rem-font-size-medium-7: 1.4rem;
// 24px
$rem-font-size-large-1: 1.5rem;
// 28.8px
$rem-font-size-large-2: 1.8rem;
// 32px
$rem-font-size-large-3: 2rem;
// 42px
$rem-font-size-huge-1: 2.625rem;
// 44.8px
$rem-font-size-huge-2: 2.8rem;
// 72px
$rem-font-size-huge-3: 4.5rem;
