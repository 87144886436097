@import '../../../styles/variables.scss';

.auth-loader {
  pointer-events: none;
  position: fixed;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  &__spinner-wrapper {
    position: relative;
    width: 70px;
    border-color: $green;
    .material-icons {
      position: absolute;
      font-size: 25px;
      left: 23px;
      top: 22px;
      color: $green;
      overflow: unset;
    }
    .css-3fvcat {
      position: static;
    }
    span {
      box-sizing: unset !important;
    }
  }
}