$tablet-width: 768px;
$desktop-width: 1025px;

@mixin desktop-large {
  @media screen and (min-width: 1920px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin tablet-up {
  @media (min-width: #{$tablet-width}) {
    @content;
  }
}

@mixin tablet-landscape {
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin mobile-landscape {
  @media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (orientation: landscape) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: 500px) {
    @content;
  }
}

@mixin mobile-and-tablet {
  @media screen and (max-width: 420px),
  (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin print {
  @media print {
    @content;
  }
}

@mixin ie10-up {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}

@mixin edge {
  @supports (-ms-ime-align: auto) {
    @content;
  }
}
