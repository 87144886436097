@import '../../../../styles';

.ReactModal__Overlay,
.ReactModal__Content {
  transition: opacity 500ms ease-in-out;
  opacity: 0;

  &--after-open {
    opacity: 1;
  }

  &--before-close {
    opacity: 0;
  }
}

.dialog-modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.9);
  z-index: 1000;

  &.form-modal {
    background: rgba(0, 0, 0, 0.5);
  }

  &.tutorial-modal {
    background: rgba(0, 0, 0, 0.6);
  }
}

.dialog-modal {
  outline: none;

  > div {
    min-width: 90%;
    max-width: 90%;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
    background: $superlight-grey;
    margin: auto;
    border-radius: 4px;
    outline: none;
    overflow-y: auto;
    overflow-x: hidden;

    @include mobile {
      margin-top: 55px;
    }

    @include desktop {
      min-width: 960px;
      max-width: 1024px;
      padding: 30px 50px;
    }
  }

  .redirect-modal {
    padding: 20px;
    min-width: 550px;
  }

  &.share {
    width: 100%;
    height: 100%;

    @include desktop {
      width: auto;
      height: auto;
    }
  }

  .dialog-modal-close-btn {
    font-family: 'Open Sans', sans-serif;
    position: absolute;
    top: 12px;
    right: 17px;
    padding: 0;
    border: none;
    color: $superlight-grey;
    font-weight: 100;
    font-size: 3rem;
    z-index: 1;
    line-height: 26px;
    font-weight: 100;

    &:hover {
      color: white;
      box-shadow: none;
    }
  }

  #forms-container {
    padding: 0px 10px;

    @include mobile {
      margin-top: 0;
      height: 100%;
    }
  }
}
