$blue: #214C6C;
$dark-blue: #1D2231;
$light-blue: #66789A;
$ultradark-grey: #1a1a1a;
$superdark-grey: #4a4a4a;
$dark-grey: #999999;
$medium-grey: #cccccc;
$light-grey: #e8e8e8;
$superlight-grey: #f1f1f1;
$ultralight-grey: #f8f8f8;
$accent: #323b4d;
$success: #009b65;
$warning: #d5dd7e;
$error: #cc6666;
$linked-in-blue: #0077b5;
$green: #009b65;
